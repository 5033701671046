import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
//
import { BrowserRouter as Router } from "react-router-dom";
import { serviceOptions as serviceOptionsHrmnetApi } from "./services/hrmnet-api/serviceOptions";

import { createBrowserHistory } from "history";

// redux
import { Provider } from "react-redux";

//
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

//
import { createAxiosApiInstance } from "./services/axiosConfig";
import { MsalProvider } from "@azure/msal-react";
import { MsalConfig } from "./azure/azure-authentication-config";

// configure process.env
require("dotenv").config();

// browser history for services call
const history = createBrowserHistory();

serviceOptionsHrmnetApi.axios = createAxiosApiInstance(
  window.REACT_APP_API_URL,
  store,
  history
);

const cleared =
  localStorage.getItem("hasCleared" + "2024-10-04T18:01:52GMT+0800") == "true";

function deleteAllCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

if (!cleared) {
  localStorage.clear();
  sessionStorage.clear();
  deleteAllCookies();
}
localStorage.setItem("hasCleared" + "2024-10-04T18:01:52GMT+0800", true);

ReactDOM.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <PersistGate loading={null} persistor={persistor}>
      <MsalProvider instance={MsalConfig()}>
        <Router>
          <App />
        </Router>
      </MsalProvider>
    </PersistGate>
    {/* </React.StrictMode> */}
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
